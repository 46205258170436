<template>
	<div>
		<b-row style="padding-bottom: 1em">
			<b-col lg="4">
				<b-tabs v-model="selectedTabIndex" justified>
					<b-tab title="All time" active />
					<b-tab title="добавления" />
					<b-tab title="обновления" />
				</b-tabs>
			</b-col>
		</b-row>
		<b-row v-if="selectedTabIndex > 0">
			<b-col lg="12">
				<date-tabs />
			</b-col>
		</b-row>

		<b-card>
			<b-row class="mb-1">
				<b-col lg="3">
					<label>Person ID</label>
					<b-input-group>
						<b-form-input
							v-model="personID_"
							placeholder="Номер документа"
							@keyup.enter="
								val => {
									personID = val.target.value
									tableParams.page = 1
								}
							"
						/>
						<b-input-group-append
							v-if="personID_"
							is-text
							style="cursor: pointer"
							@click="
								() => {
									personID_ = ''
									personID = ''
								}
							"
						>
							<feather-icon icon="XIcon" class="cursor-pointer" />
						</b-input-group-append>
					</b-input-group>
				</b-col>
				<b-col>
					<label>&nbsp;</label>
					<b-input-group>
						<b-button variant="primary" @click="isAddNewUserSidebarActive = true">
							<span class="text-nowrap">Создать</span>
						</b-button>
					</b-input-group>
					<sidebar-create-new
						:visible.sync="isAddNewUserSidebarActive"
						:fields="[
							{
								key: 'id_photo',
								label: 'Фото',
								filter: 'image',
								base64: true,
							},
							{
								key: 'person_id',
								label: 'Имя',
								filter: 'string',
							},
							{
								key: 'group_id',
								label: 'Группа',
								filter: 'number',
							},
						]"
						:api="BaseOnetoN"
						:data="{}"
						@refresh="
							() => {
								ctx.refs.table.fetchList()
							}
						"
					/>
				</b-col>
			</b-row>
			<editable-table ref="table" :api="BaseOnetoN" :fields="fields" :params="tableParams" :on-click="onClick">
				<template #cell(created)="data">
					<span>
						{{ new Date(data.item.created).toLocaleString() }}
					</span>
				</template>
				<template #cell(modified)="data">
					<span>
						{{ new Date(data.item.modified).toLocaleString() }}
					</span>
				</template>

				<template #cell(photo)="data" class="px-1">
					<b-media vertical-align="center">
						<template>
							<b-link
								v-if="data.item.minio_path"
								:href="filterMinioURL(data.item.minio_path || '')"
								target="_blank"
							>
								<b-avatar
									size="32"
									:src="filterMinioURL(data.item.cropped_minio_path || data.item.minio_path || '')"
									:variant="`light-primary`"
								/>
							</b-link>
						</template>
					</b-media>
				</template>
			</editable-table>
		</b-card>
		<b-modal id="modal-lg" ref="modal" ok-only ok-title="Close" centered size="lg" title="Информация">
			<div class="employee-task d-flex justify-content-between align-items-center mb-1">
				<b-media v-if="requestData" no-body>
					<b-media-aside class="mr-75">
						<b-avatar rounded size="100" :src="filterMinioURL(requestData.photo.minio_path || '')" />
					</b-media-aside>
					<b-media-body class="my-auto">
						<h6 class="mb-0">
							<b-badge>
								{{ requestData.id }}
							</b-badge>
						</h6>
						<div>{{ new Date(requestData.created).toLocaleString() }}</div>
						<div>
							{{
								requestData.response &&
								requestData.response.data &&
								requestData.response.data.results &&
								requestData.response.data.results.length
									? Math.max(...requestData.response.data.results.map(r => r.recognitionValue)) + '%'
									: '-'
							}}
						</div>
					</b-media-body>
				</b-media>
			</div>
			<div
				v-for="row in rowData"
				:key="row.personId"
				class="employee-task d-flex justify-content-between align-items-center mb-1"
			>
				<b-media no-body>
					<b-media-aside class="mr-75">
						<b-link :href="filterMinioURL(row.minio_path || '')" target="_blank">
							<b-avatar rounded size="64" :src="filterMinioURL(row.cropped_minio_path || '')" />
						</b-link>
					</b-media-aside>
					<b-media-body class="my-auto">
						<h6 class="mb-0">
							{{ row.personId }}
						</h6>
						<span>{{ row.recognitionValue }}%</span>
					</b-media-body>
				</b-media>
			</div>
		</b-modal>
	</div>
</template>

<script>
import {
	BCard,
	BCardText,
	BTabs,
	BTab,
	BMedia,
	BAvatar,
	BLink,
	BModal,
	BMediaAside,
	BMediaBody,
	BBadge,
	BRow,
	BCol,
	BInputGroup,
	BInputGroupAppend,
	BFormInput,
	BButton,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import QueriesTable from './queries/QueriesTable.vue'
import DateTabs from './statistics/DateTabs.vue'
import EditableTable from '@/components/EditableTable.vue'
import SidebarCreateNew from '@/components/SidebarCreateNew.vue'
import { BaseOnetoN } from '@/api/schema'
import { filterMinioURL } from '@/api/schema'

const fields = ref([
	{
		visible: true,
		label: 'ID',
		key: 'id',
	},
	{
		visible: true,
		label: 'Фото',
		key: 'photo',
	},
	{
		visible: true,
		label: 'Person ID',
		key: 'person_id',
	},
	{
		visible: true,
		label: 'Group ID',
		key: 'group_id',
	},
	{
		visible: true,
		label: 'Дата и время добавления',
		key: 'created',
	},
	{
		visible: true,
		label: 'Дата и время последнего обновления',
		key: 'modified',
	},
])

export default {
	components: {
		BTab,
		BTabs,
		BCard,
		BCardText,
		QueriesTable,
		DateTabs,
		EditableTable,
		BMedia,
		BAvatar,
		BLink,
		BModal,
		BMediaAside,
		BMediaBody,
		BBadge,
		BRow,
		BCol,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
		SidebarCreateNew,
		BButton,
	},
	setup(props, ctx) {
		const rowData = ref([])
		const requestData = ref()
		const personID = ref('')
		const personID_ = ref('')
		const selectedTabIndex = ref('')
		const isMatch = ref(undefined)

		const tableParams = computed(() => {
			if (selectedTabIndex.value == 0) {
				return {
					person_id: personID.value,
					is_match: isMatch?.value?.value,
				}
			}
			if (selectedTabIndex.value == 1) {
				return {
					created_date_start: ctx.parent.$store.state.appstats.start_date,
					created_date_end: ctx.parent.$store.state.appstats.end_date,
					person_id: personID.value,
					is_match: isMatch?.value?.value,
				}
			}
			return {
				updated_date_start: ctx.parent.$store.state.appstats.start_date,
				updated_date_end: ctx.parent.$store.state.appstats.end_date,
				person_id: personID.value,
				is_match: isMatch?.value?.value,
			}
		})

		watch([tableParams], () => {
			setTimeout(() => {
				ctx.refs.table.fetchList()
			}, 1)
		})

		function onClick(item) {
			if (!item?.response?.data?.results.length) return
			rowData.value = item?.response?.data?.results || []
			requestData.value = item || {}
			ctx.refs.modal.show()
		}

		return {
			onClick,
			BaseOnetoN,
			fields,
			VUE_APP_MINIO: process.env.VUE_APP_MINIO,
			rowData,
			requestData,
			personID,
			personID_,
			isMatch,
			tableParams,
			selectedTabIndex,
			isAddNewUserSidebarActive: ref(false),
			filterMinioURL,
		}
	},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
